import React from "react"
import {Container} from 'react-bootstrap'
import {Link} from 'gatsby'

//components
import Header from "./header"

class Layout extends React.Component{
    render(){
        const {children,hideNav} = this.props;
        return (
            <>
                <Header hideNav={hideNav} />
                <main>{children}</main>
                <footer className="footer has-bg-white has-padding-y-tiny">
                    <Container>
                        <ul>
                            <li>
                                <Link to="/over-de-bibliotheek-verrijker/">Over de Bibliotheek Verrijker</Link>
                            </li>
                            <li>
                                <Link to="/privacy-statement/">Privacy statement</Link>
                            </li>
                        </ul>                    
                            
                    </Container>
                </footer>
            </>
        )
    }    
   
}

export default Layout

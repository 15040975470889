import React from 'react'
import { Navbar, Nav, Container } from 'react-bootstrap'
import { Link } from 'gatsby'

//components
import Logo from '../assets/images/logo.svg';

const Header = ({hideNav}) => (
    <>
        <Navbar expand="lg">
            <Container>
                <Navbar.Brand href="/"><img src={ Logo } alt="" className="navbar-logo" /></Navbar.Brand>
            </Container>
        </Navbar>
        {!hideNav &&
            <Navbar expand="lg">
                <Container>
                    <Nav>
                        <Link className="nav-link" activeClassName="is-active" to="/">Home</Link>
                        <Link className="nav-link" activeClassName="is-active" to="/over-de-bibliotheek-verrijker/">Over de Bibliotheek Verrijker</Link>
                    </Nav>
                </Container>
            </Navbar>
        }
    </>
)

export default Header
